/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/angular@1.8.3/angular.min.js
 * - /npm/angular-animate@1.8.3/angular-animate.min.js
 * - /npm/angular-cookies@1.8.3/angular-cookies.min.js
 * - /npm/angular-sanitize@1.8.3/angular-sanitize.min.js
 * - /npm/foundation-sites@6.3.0/dist/js/foundation.min.js
 * - /npm/lodash@4.17.21/lodash.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
